// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-fq722D4LsAuXvvvo3_yOSWgXPyGMoWw",
  authDomain: "airsoftlegends-98c9f.firebaseapp.com",
  projectId: "airsoftlegends-98c9f",
  storageBucket: "airsoftlegends-98c9f.appspot.com",
  messagingSenderId: "971971961739",
  appId: "1:971971961739:web:38101260374eff7cf3c58e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth =  getAuth(app)