import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Avatar } from '@mui/material';
import Navbar from '../components/Navbar';
import "../Styles/Home.css";

import IconButton from '@mui/material/IconButton';


import Fingerprint from '@mui/icons-material/Fingerprint';


export const Home = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `http://airsoftlegendsapp.com/API_AL/connect.php?email=${user.email}`;

  function peticionGet() {
    axios.get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user)
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error);
      });
  }

  useEffect(() => {
    peticionGet();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  const arenasLink = async() => {
    try {
      navigate('/arenas')
    } catch (error) {
      console.log(error);
    }
  }

  //-> como hacer un boton
  //<Button variant="contained" onClick={arenasLink}>Arenas</Button>
  return (
    <React.Fragment>
      <Navbar></Navbar>
      <div>
      <h1>Welcome to AirsoftLegens</h1>

      <div className='center'>
        <Avatar alt="Remy Sharp" src={user.photoURL} sx={{ width: 100, height: 100 }} />
      </div>
      
      <h2>{user && user.displayName}</h2>
      <table>
      <thead>
        <tr>
        </tr>
      </thead>
      <tbody>
        {error ? (
          <tr>
            <td colSpan='2'>Error: {error.message}</td>
          </tr>
        ) : data === null ? (
          <tr>
            <td colSpan='2'>Cargando datos...</td>
          </tr>
        ) : data.length > 0 ? (
          data.map((item) => (
            <>
              <tr key={`id-${item.id}`}>
                <td><strong>Al_Id:</strong></td>
                <td>{item.id}</td>
              </tr>
              <tr key={`name-${item.id}`}>
                <td><strong>Nombre:</strong></td>
                <td>{item.name}</td>
              </tr>
              <tr key={`email-${item.id}`}>
                <td><strong>Email:</strong></td>
                <td>{item.email}</td>
              </tr>
              <tr key={`nickname-${item.id}`}>
                <td><strong>Apodo:</strong></td>
                <td>{item.nickname}</td>
              </tr>
              <tr key={`connections-${item.id}`}>
                <td><strong>Connecciones:</strong></td>
                <td>{item.connections}</td>
              </tr>
              <tr key={`description-${item.id}`}>
                <td><strong>Description:</strong></td>
                <td>{item.description}</td>
              </tr>
              <tr key={`followers-${item.id}`}>
                <td><strong>Seguidores:</strong></td>
                <td>{item.followers}</td>
              </tr>
              <tr key={`games-${item.id}`}>
                <td><strong>Partidas Jugadas:</strong></td>
                <td>{item.games}</td>
              </tr>
              <tr key={`host-${item.id}`}>
                <td><strong>Anfitrion:</strong></td>
                <td>{item.host}</td>
              </tr>
              <tr key={`level-${item.id}`}>
                <td><strong>Nivel:</strong></td>
                <td>{item.level}</td>
              </tr>
              <tr key={`weapons-${item.id}`}>
                <td><strong>Armas:</strong></td>
                <td>{item.weapons}</td>
              </tr>
            </>
          ))
        ) : (
          <tr>
            <td colSpan='2'>No se encontraron datos.</td>
          </tr>
        )}
      </tbody>
    </table>
    
    <div className='footer'>
       <IconButton aria-label="fingerprint" color="secondary" onClick={handleLogout}> LOG OUT 
        <Fingerprint></Fingerprint>
      </IconButton>
    </div>

    
      
    </div>

    </React.Fragment>
    
  );
};

export default Home;
