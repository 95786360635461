import React from 'react';
import Navbar from '../../components/Navbar';

export const Stores = () => {
  return (
    
    <React.Fragment>
        <Navbar></Navbar>
        <div>Stores</div>
    </React.Fragment>
  )
  
}

export default Stores;