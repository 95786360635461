import React from 'react'
import Navbar from '../../components/Navbar';

export const Campaigns = () => {
  return (
    <React.Fragment>
      <Navbar></Navbar>
      <div>Campaigns</div>
    </React.Fragment>
  )
}

export default Campaigns;
