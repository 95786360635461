//rafc
import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material';
import { Button } from '@mui/material';
import Navbar from '../../components/Navbar';
import { Avatar } from '@mui/material';
import axios from 'axios';


export const Arenas = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [data, setData] = useState(null); // Initialize as null for loading state
    const [error, setError] = useState(null); // Track errors
    const baseUrl = `http://airsoftlegendsapp.com/API_AL/arenas.php`;

    function peticionGet() {
      axios.get(baseUrl)
        .then((response) => {
          console.log(response.data);
          console.log(user)
          setData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setError(error);
        });
    }
  
    useEffect(() => {
      peticionGet();
    }, []);

    
    return (
        <React.Fragment>
            <Navbar></Navbar>
            <div>Arenas</div>
            <table>
          <thead>
            <tr>
              <th>Arena_Id</th>
              <th>Nombre</th>
              <th>Puntaje</th>
              <th>Direccion</th>
              <th>Telefono</th>
              <th>Tipo de Arena</th>
              <th>Pagina Web</th>
              <th>Descripción</th>
              <th>Locación</th>
              <th>Imagen</th>

            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan='11'>Error: {error.message}</td>
              </tr>
            ) : data === null ? (
              <tr>
                <td colSpan='11'>Cargando datos...</td>
              </tr>
            ) : data.length > 0 ? (
              data.map((item) => (
                <tr key={`id-${item.id}`}>
                  <td>{item.id_arena}</td>
                  <td>{item.name}</td>
                  <td>{item.score}</td>
                  <td>{item.address}</td>
                  <td>{item.phone}</td>
                  <td>{item.arena_Type}</td>
                  <td>{item.website}</td>
                  <td>{item.description}</td>
                  <td>{item.location}</td>
                  <td><Avatar alt="Remy Sharp" src={item.image} sx={{ width: 50, height: 50 }} /></td>
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='11'>No se encontraron datos.</td>
              </tr>
            )}
          </tbody>
        </table>

        </React.Fragment>
        

    );
}

export default Arenas;

