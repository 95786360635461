import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createRoutesFromElements,
  RouterProvider,
  Route,
  createBrowserRouter,
} from "react-router-dom"
import Home from './pages/Home';
import Login from './pages/Login';
import Arenas from './pages/Arenas/Arenas';
import Protected from './components/Protected';
import Campaigns from './pages/Campaigns/Campaigns';
import Stores from './pages/Stores/Stores';
import Legends from './pages/Legends/Legends';

const router = createBrowserRouter (
  createRoutesFromElements (
    <Route path='/' element={<App />}>
      <Route path='login' element={<Login/>} />
      <Route path='arenas' element={<Arenas/>} />
      <Route path='campaigns' element={<Campaigns/>}/>
      <Route path='stores' element={<Stores/>}/>
      <Route path='legends' element={<Legends/>}/>
      <Route path='/' element={<Protected/>}>
        <Route path='/' index element={<Home />} />
      </Route> 
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);

// Ruta original -- Isain
/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
