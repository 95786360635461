import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Avatar } from '@mui/material'

export const Legends = () => {

    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
  
    const [data, setData] = useState(null); // Initialize as null for loading state
    const [error, setError] = useState(null); // Track errors
    const baseUrl = `http://airsoftlegendsapp.com/API_AL/legends.php`;
  
    function peticionGet() {
      axios.get(baseUrl)
        .then((response) => {
          console.log(response.data);
          console.log(user)
          setData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setError(error);
        });
    }
  
    useEffect(() => {
      peticionGet();
    }, []);




  return (
    <React.Fragment>
        <Navbar></Navbar>
        <div>Legends</div>
        <table>
  <thead>
    <tr>
      <th>Al_Id</th>
      <th>Nombre</th>
      <th>Email</th>
      <th>Apodo</th>
      <th>Conexiones</th>
      <th>Descripción</th>
      <th>Seguidores</th>
      <th>Partidas Jugadas</th>
      <th>Anfitrión</th>
      <th>Nivel</th>
      <th>Armas</th>
      <th>Photo</th>

    </tr>
  </thead>
  <tbody>
    {error ? (
      <tr>
        <td colSpan='11'>Error: {error.message}</td>
      </tr>
    ) : data === null ? (
      <tr>
        <td colSpan='11'>Cargando datos...</td>
      </tr>
    ) : data.length > 0 ? (
      data.map((item) => (
        <tr key={`id-${item.id}`}>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.email}</td>
          <td>{item.nickname}</td>
          <td>{item.connections}</td>
          <td>{item.description}</td>
          <td>{item.followers}</td>
          <td>{item.games}</td>
          <td>{item.host}</td>
          <td>{item.level}</td>
          <td>{item.weapons}</td>
          <td><Avatar alt="Remy Sharp" src={item.image} sx={{ width: 50, height: 50 }} /></td>
          
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan='11'>No se encontraron datos.</td>
      </tr>
    )}
  </tbody>
</table>

        
    </React.Fragment>
    
  )
}

export default Legends;
